import { ArrowLeftOutlined, DeleteOutlined, FormOutlined, PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { Input } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { useSelector } from "../../../redux-store/reducer";
import { AppDispatch } from "../../../redux-store/store";

import "../../../style/stores.css";
import previousPage from "utils/previousPage";

import { getCourseActions } from "redux-store/action/learningModule/learningActions";
import { dateFormatterNew } from "utils/common";
import { deleteCourseService } from "services/learningModule/courseService";
import DeleteItem from "../common/deleteItem";

function AdminLmsDashboard() {
  const isLoading = useSelector((state: any) => state.app.isLoading);

  const courseData = useSelector((state: any) => state?.learning?.course);
  const dispatch = useDispatch<AppDispatch>();

  // const [filters, setFilters] = useState({
  //   ...additionalFilters,
  //   storeType: StoreTypeEnum.ALL,
  //   duration: DurationEnum.ALL
  // });
  const [pageNumber, setPageNumber] = useState(1);

  const [courseDetails, setCourseDetails] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getCourseActions());
  }, []);

  useEffect(() => {
    setCourseDetails(courseData);
  }, [courseData]);

  const searchCourse = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const FS = courseData.filter((item: any) =>
      (item?.courseName?.toLowerCase())?.includes(value.toLowerCase())
    );
    setCourseDetails(FS);
  };

  // const handleLoadMore = useCallback(async () => {
  //   try {
  //     const newPageNumber = pageNumber + 1;
  //     await dispatch(loadMoreStoreActions(filters, {
  //       pageNumber: newPageNumber,
  //       pageSize: DEFAULT_PAGE_SIZE
  //     }))
  //     setPageNumber(newPageNumber)
  //   } catch (error) {

  //   }
  // }, [dispatch, filters, pageNumber]);

  const [toggleDelete, setToggleDelete] = useState(false);
  const [courseName, setCourseName] = useState('');
  const [courseId, setCourseID] = useState('');

  const toggleHandler = (courseId: string, name: string) => {
    setToggleDelete(true);
    setCourseID(courseId);
    setCourseName(name)
  }


  return (
    <div className="store-v1 storeBgC">
      <header className="heading heading-container">
        <ArrowLeftOutlined onClick={previousPage} className="back-button" />
        <h1 className="page-title pr-18">Learning Module</h1>
      </header>
      <DeleteItem
        toggle={toggleDelete}
        name={courseName}
        itemsId={courseId}
        deleteService={deleteCourseService}
        closeModal={(e: any) => {
          setToggleDelete(e);
        }} />
      <Link to="/admin/lms/add-course">
        <div className="addIcon">
          <PlusOutlined className="plusIcon" />
        </div>
      </Link>
      <main>
        <div className="search">
          <Input prefix={<SearchOutlined />} placeholder="Search Course" onChange={searchCourse} />
        </div>

        {
          (courseDetails && courseDetails?.length > 0) && courseDetails?.map((item, index) => {
            return (
              <div key={index}>
                <div className="course-list">
                  <Link
                    to={`/admin/lms/course-detail?courseId=${item?.courseId}`}
                    className="linktoB">
                    <div className="dflex">
                      {item?.thumbnailUrl ?
                        <img src={item?.thumbnailUrl} alt=""
                          width="140px" style={{ borderRadius: "12px" }} height="100px" />
                        :
                        <img src="https://www.ultimatesource.toys/wp-content/uploads/2013/11/dummy-image-landscape-1-1024x800.jpg" alt=""
                          width="140px" style={{ borderRadius: "12px" }} height="100px" />
                      }
                    </div>
                  </Link>
                  <div className="storeConlis ">
                    <Link
                      to={`/admin/lms/course-detail?courseId=${item?.courseId}`}
                      className="linktoB">
                      <div>
                        <div className="shoptitle">
                          <div className="storeIdTxt">
                            <span style={{ fontSize: "16px", fontWeight: "bold" }}>{item?.courseName}</span>
                          </div>
                        </div>
                        <div
                          className="flexSpace storeAddTxt">
                          <span><span className="fontb">Launch: </span>{dateFormatterNew(item?.launchedDate)}</span>
                        </div>
                        <div
                          className="flexSpace storeAddTxt">
                          <span><span className="fontb">Due Date: </span>{dateFormatterNew(item?.dueDate)}</span>
                        </div>
                      </div>
                    </Link>
                    <div className={item?.isActive ? "activetag" : "inActivetag"} style={{ background: "#f0f2f7", width: "60px", marginTop: "4px" }}>
                      <span
                        className={item?.isActive ? "blinker" : "blinker-inActive"}
                      >
                      </span>
                      <span>{item?.isActive ? "Active" : "Inactive"}</span>
                    </div>
                    <div className="dflex mt-8" style={{ gap: "28px", alignItems: "center" }}>
                      <span style={{ fontSize: "14px", padding: "4px 8px", background: "#f0f2f7", borderRadius: "12px" }}><Link to={`/admin/lms/create-quiz?courseId=${item?.courseId}&courseName=${item?.courseName}`} className="linkto">Add Quiz  <PlusOutlined style={{ fontSize: "bold" }} /></Link></span>
                      <Link to={`/admin/lms/add-course?courseId=${item?.courseId}`} className="linkto">
                        <span><FormOutlined /></span></Link>
                      <span><DeleteOutlined onClick={() => toggleHandler(item?.courseId, `Course: ${item?.courseName}`)} className="deleteIcon" /></span>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
      </main>
    </div>
  );
}

export default AdminLmsDashboard;