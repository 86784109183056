enum productActionsType {
    GET_PRODUCT = "GET_PRODUCT",
    GET_PRODUCT_CATEGORY = "GET_PRODUCT_CATEGORY",
    GET_PRODUCT_BRAND = "GET_PRODUCT_BRAND",
    SET_ORDER_ITEM = "SET_ORDER_ITEM",
    GET_PRODUCT_SCHEME = "GET_PRODUCT_SCHEME"
}

export {
    productActionsType
}