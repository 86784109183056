enum UserRole {
    ADMIN = "ADMIN",
    DIRECTOR = "DIRECTOR",
    RSM = "RSM",
    ASM = "ASM",
    SO = "SO",
    SSM = "SSM",
    CHANNEL = "CHANNEL"
}

enum GetUserRole {
    ADMIN = "Admin",
    DIRECTOR = "Director",
    RSM = "Manager",
    ASM = "ASM",
    SO = "SO",
    SSM = "Salesman",
    CHANNEL = "Channel"

}

enum DurationEnum {
    ALL = 'ALL',
    TODAY = 'TODAY',
    WEEK = 'WEEK'
}

enum DiscountType {
    PERCENTAGE = "PERCENTAGE",
    VALUE = "VALUE"
}

enum TimelineEnum {
    TODAY = 'TODAY',
    WEEK = 'WEEK',
    MONTH = 'MONTH',
    YEAR = 'YEAR',
    QUARTER = 'QUARTER'
}

enum VisitTypeEnum {
    PHYSICAL = "PHYSICAL",
    TELEVISIT = "TELEVISIT"
}
export {
    UserRole,
    GetUserRole,
    DurationEnum,
    DiscountType,
    TimelineEnum,
    VisitTypeEnum
}