enum StoreBillingEnum {
    BILLED = 'BILLED',
    UNBILLED = 'UNBILLED',
    ALL = 'ALL'
}

enum StoreTypeEnum {
    ALL = 'all',
    NEW = 'new'
}

export {
    StoreBillingEnum,
    StoreTypeEnum
}