import React from 'react'

function Header() {
    return (
        <div className='plogo'>
            {/* <img
                src={process.env.PUBLIC_URL + "/logo.png"}
                alt="logo"
                width="140px"
                height="140px"
                className='applogo'
            /> */}
        </div>
    )
}

export default Header