const statesAndUTs = [
  { id: 1, name: 'Andaman and Nicobar Islands', stateCode: 'AN' },
  { id: 2, name: 'Andhra Pradesh', stateCode: 'AP' },
  { id: 3, name: 'Arunachal Pradesh', stateCode: 'AR' },
  { id: 4, name: 'Assam', stateCode: 'AS' },
  { id: 5, name: 'Bihar', stateCode: 'BR' },
  { id: 6, name: 'Chandigarh', stateCode: 'CH' },
  { id: 7, name: 'Chhattisgarh', stateCode: 'CT' },
  { id: 8, name: 'Dadra and Nagar Haveli', stateCode: 'DH' },
  { id: 9, name: 'Daman and Diu', stateCode: 'DD' },
  { id: 10, name: 'Delhi', stateCode: 'DL' },
  { id: 11, name: 'Goa', stateCode: 'GA' },
  { id: 12, name: 'Gujarat', stateCode: 'GJ' },
  { id: 13, name: 'Haryana', stateCode: 'HR' },
  { id: 14, name: 'Himachal Pradesh', stateCode: 'HP' },
  { id: 15, name: 'Jammu and Kashmir', stateCode: 'JH' },
  { id: 16, name: 'Jharkhand', stateCode: 'JH' },
  { id: 17, name: 'Karnataka', stateCode: 'KA' },
  { id: 18, name: 'Kerala', stateCode: 'KL' },
  { id: 19, name: 'Ladakh', stateCode: 'LA' },
  { id: 20, name: 'Lakshadweep', stateCode: 'LD' },
  { id: 21, name: 'Madhya Pradesh', stateCode: 'MP' },
  { id: 22, name: 'Maharashtra', stateCode: 'MH' },
  { id: 23, name: 'Manipur', stateCode: 'MN' },
  { id: 24, name: 'Meghalaya', stateCode: 'ML' },
  { id: 25, name: 'Mizoram', stateCode: 'MZ' },
  { id: 26, name: 'Nagaland', stateCode: 'NL' },
  { id: 27, name: 'Odisha', stateCode: 'OR' },
  { id: 28, name: 'Puducherry', stateCode: 'PY' },
  { id: 29, name: 'Punjab', stateCode: 'PB' },
  { id: 30, name: 'Rajasthan', stateCode: 'RJ' },
  { id: 31, name: 'Sikkim', stateCode: 'SK' },
  { id: 32, name: 'Tamil Nadu', stateCode: 'TN' },
  { id: 33, name: 'Telangana', stateCode: 'TG' },
  { id: 34, name: 'Tripura', stateCode: 'TR' },
  { id: 35, name: 'Uttar Pradesh', stateCode: 'UP' },
  { id: 36, name: 'Uttarakhand', stateCode: 'UT' },
  { id: 37, name: 'West Bengal', stateCode: 'WB' }
];

export default statesAndUTs;
