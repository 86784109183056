import React from 'react'
import { useLocation } from 'react-router-dom';

export default function VisitPictures(props: any) {
  const { state } = useLocation();

  return (
    <div className='visPicCont'>
      {
        state?.visitDetail?.image &&
        <img
          src={state?.visitDetail?.image}
          alt='pic'
          className='visPicImg'
          />
      }
    </div>
  )
}
