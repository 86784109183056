import React from "react";
import { Link, useNavigate } from "react-router-dom";
import "../../style/login.css";
import { Form, message } from "antd";
import { loginSchema } from "../../utils/formValidations";
import { getProfileService, loginService } from "services/authService";
import { useAuth } from "context/AuthContext";
import { setItemIntoLS } from "utils/common";
import { LS_KEYS } from "../../app-constants";
import { useDispatch } from "react-redux";
import { setLoaderAction } from "../../redux-store/action/appActions";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import HookFormInputField from "component/HookFormInputField";
export const Login = () => {
  const { setAuthState } = useAuth();
  const redirect = useNavigate();
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
  } = useForm({
    mode: "all",
    resolver: yupResolver(loginSchema),
    defaultValues: {
      phone: "",
      password: "",
    }
  })

  const onSubmit = async (values: any) => {
    const { phone, password } = values;
    try {
      setAuthState(p => ({
        ...p,
        isLoading: true
      }))
      dispatch(setLoaderAction(true));

      const response = await loginService({ phone: Number(phone), password });
      dispatch(setLoaderAction(false));
      if (response?.data?.status === 200) {
        message.success("Sign-in Successfully")
        const token = response?.data?.data.accessToken;
        setItemIntoLS(LS_KEYS.accessToken, token);
        const profileRes = await getProfileService();
        const userData = profileRes?.data?.data;
        setItemIntoLS(LS_KEYS.userData, JSON.stringify(userData));
        setAuthState(p => ({
          ...p,
          isLoading: false,
          authenticated: true,
          user: userData
        }))
       await redirect(userData?.role === "SSM" || userData?.role === "CHANNEL" ? "/dashboard" : "/admin/dashboard", { state: token });
      }
    } catch (error: any) {
      dispatch(setLoaderAction(false));
      message.error(error?.response?.data?.message  || "Something Went Wrong")
    }
  };

  return (
    <>
  
      <div className="Fcontainer">
        <div className="form-container">
          <div style={{textAlign:"center", marginBottom:"60px", marginRight: "20px"}}>
          <img src="https://hillberg.saleofast.com/images/logo.png" width={200} height={60} alt="login-logo"/>
          </div>
          <div className="login-title">Sign In</div>

          <Form
            onFinish={handleSubmit(onSubmit)}
            autoComplete="off"
            name="validateOnly" layout="vertical" >

            <HookFormInputField
              control={control}
              type=""
              name="phone"
              placeholder="User Id"
              label={"User Id"}
              required
              style={{height:"42px", fontSize:"16px"}}
            />
            <span style={{marginBottom:"10px"}}></span>
            <HookFormInputField
              control={control}
              type="password"
              name="password"
              placeholder="Password"
              label={"Password"}
              required
              style={{height:"42px"}}
            />
            <div><Link to="./auth/forgot-password">Forgot Password</Link></div>
            <button
              type="submit"
              style={{marginTop: "70px", height:"42px", borderRadius: "6px"}}
              className="loginbtn"
            >
              Submit
            </button>
          </Form>
        </div>
      </div>
    </>
  );
};
