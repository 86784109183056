import { API_ENDPOINTS } from "app-constants";
import { apiService } from "./apiService";
import { CreateUserReq, CreateUserRes, DeleteUserReq, GetSSMUsersRes, GetUserLearningRoleRes, GetUserRes, UpdateUserReq, UpdateUserRes, deleteUserRes } from "types/User";
import { IProductRes } from "types/Product";

function getSSMUsersService() {
    return apiService.get<GetSSMUsersRes>(
        API_ENDPOINTS.getSSMUsersList
    );
}

function getManagerService() {
    return apiService.get<GetSSMUsersRes>(
        API_ENDPOINTS.getManagerList
    );
}

function getUsersLearningRoleService() {
    return apiService.get<GetUserLearningRoleRes>(
        API_ENDPOINTS.getUsersLearningRoleList
    );
}

function getStoresByEmpIdService(empId: number) {
    return apiService.get<IProductRes>(`${API_ENDPOINTS.getstoresByEmpId}/${empId}`)
}

function getStoresByBeatIdService(empId: number) {
    return apiService.get<IProductRes>(`${API_ENDPOINTS.getstoresByBeatId}/${empId}`)
}

function getUserDetailsByEmpIdService(empId: string) {
    return apiService.get<GetUserRes>(`${API_ENDPOINTS.getUserDetails}/${empId}`)
}

function deleteUserService(empId: DeleteUserReq) {
    return apiService.delete<deleteUserRes>(
        `${API_ENDPOINTS.deleteUser}/${empId}`
    );
}

function updateUserService(data: UpdateUserReq) {
    return apiService.post<UpdateUserRes>(
        API_ENDPOINTS.updateUser,
        data
    );
}


function createUserService(data: CreateUserReq) {
    return apiService.post<CreateUserRes>(
        API_ENDPOINTS.createUser,
        data
    );
}
export {
    getSSMUsersService,
    getUsersLearningRoleService,
    getStoresByEmpIdService,
    createUserService,
    getManagerService,
    getUserDetailsByEmpIdService,
    deleteUserService,
    updateUserService,
    getStoresByBeatIdService
}