import { ArrowLeftOutlined, DeleteOutlined, FormOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Select, TreeSelect } from 'antd';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { getProductCategoryActions, getProductsActions } from 'redux-store/action/productAction';
import { AppDispatch } from 'redux-store/store';
import previousPage from 'utils/previousPage';
import '../../style/orderList.css';
import { deleteProductService } from 'services/productService';
import DeleteItem from '../common/deleteItem';
import { useAuth } from 'context/AuthContext';
import { UserRole } from 'enum/common';
import { handleImageError } from 'utils/common';
import Category from '../productCategory/category';
import { title } from 'process';
export default function AdminProduct() {
  const productData = useSelector((state: any) => state?.product?.productList);
  const productCategoryData = useSelector((state: any) => state?.product?.category);
 
  const dispatch = useDispatch<AppDispatch>();
  const [productList, setProductList] = useState<any[]>([]);
  const [multiCatList, setMultiCatList] = useState<any[]>([]);

  const { authState } = useAuth();
  useEffect(() => {
    dispatch(getProductsActions({}));
    dispatch(getProductCategoryActions());

  }, []);

  useEffect(() => {
    setProductList(productData);
  }, [productData])

  // const searchProduct = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
  //   const { value } = e.target;
  //   const FS = productData.filter((item: any) =>
  //     (item?.productName?.toLowerCase() || item?.brand?.toLowerCase() || item?.caregory?.toLowerCase())?.includes(value.toLowerCase())
  //   );
  //   setProductList(FS);
  // };
  const searchProduct = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const searchTerm = value.toLowerCase();

    const FS = productData.filter((item: any) => {
        return (
            (item.productName && item?.productName?.toLowerCase().includes(searchTerm)) ||
            (item.brand && item?.brand?.name?.toLowerCase().includes(searchTerm)) ||
            (item.category && item?.category?.name?.toLowerCase().includes(searchTerm))
        );
    });

    setProductList(FS);
};



  const uniqueBrands = Array.from(new Set(productData?.map((data: any) => data?.brand?.name)));
  const optionsBrand = uniqueBrands.map((brandName: any) => ({
    label: brandName,
    value: brandName,
  }));
  const allList = [{ label: "All", value: "all" }]
  optionsBrand.splice(0, 0, ...allList);

  const handleBrandChange = (value: string) => {
    const FS = productData.filter((item: any) =>
      (item?.brand?.name?.toLowerCase())?.includes(value.toLowerCase())
    );
    setProductList(value === "all" ? productData : FS);
  };
  const uniqueCategory = Array.from(new Set(productData?.map((data: any) => data?.category?.name)));
  const optionsCategory = uniqueCategory.map((categoryName: any) => ({
    label: categoryName,
    value: categoryName,
  }));
  optionsCategory.splice(0, 0, ...allList);
const [value, setValue] = useState<any>("Category")
const handleCategoryChange = (value: any) => {
  setValue(value);
  const filteredValue = value === -1 ? null : value;
  const FS = productData.filter((item: any) => 
    filteredValue === null || item?.category.productCategoryId === filteredValue
  );

  setProductList(filteredValue === null ? productData : FS);
};
  const [toggleDelete, setToggleDelete] = useState(false);
  const [productName, setProductName] = useState('');
  const [productId, setProductID] = useState('');

  const toggleHandler = (productId: string, productName: string) => {
    setToggleDelete(true);
    setProductID(productId);
    setProductName(productName)
  }

  type Category = {
    productCategoryId: number;
    empId: number;
    name: string;
    isActive: boolean;
    isDeleted: boolean;
    createdAt: string;
    updatedAt: string;
    parentId: number | null;
    children: Category[];
    parent: Category | null;
  };

  type CascaderOption = {
    value: number;
    label: string;
    children?: CascaderOption[];
  };

  // Recursive function to map data to Cascader options
  const mapCategoriesToCascaderOptions = (categories: Category[]): CascaderOption[] => {
    const map: { [key: number]: CascaderOption } = {};
  
    // Map categories to Cascader options
    categories.forEach(category => {
      map[category.productCategoryId] = {
        value: category.productCategoryId,
        label: category.name,
        children: [],
      };
    });
  
    // Build the hierarchy
    categories.forEach(category => {
      if (category.parentId !== null) {
        const parent = map[category.parentId];
        if (parent) {
          parent.children?.push(map[category.productCategoryId]);
        }
      }
    });
  
    // Return the options, including "All" option at the start
    return [
      { value: -1, label: "All" }, // Add the "All" option
      ...Object.values(map).filter(option => !categories.some(cat => cat.productCategoryId === option.value && cat.parentId !== null))
    ];
  };
  
  const allListCat = [{ productCategoryId: -1, name: "All", parentId: null }]; // Dummy data for "All" option
  
  const catDataFilter: Category[] = [...allListCat, ...productCategoryData];
  const options: CascaderOption[] = mapCategoriesToCascaderOptions(catDataFilter);
  return (
    <div className='mrb'>
      <header className="heading heading-container">
        <ArrowLeftOutlined onClick={previousPage} className="back-button" />
        <h1 className="page-title pr-18">Products</h1>
      </header>
      {authState?.user?.role === UserRole.ADMIN && <Link to="./add-new-product">
        <div className="addIcon">
          <PlusOutlined className='plusIcon' />
        </div>
      </Link>}
      <main>
        <div className='searchProduct'>
          <Input
            prefix={<SearchOutlined className='fs-16' />}
            placeholder="Search Items by Product Name, Brand, Category"
            onChange={searchProduct}
            className="searchContainer p-7"
          />
        </div>
        <div className="selection-line ">
          <div className="brand" style={{ paddingLeft: "10px" }}>
            <Select
              id="brandSelect"
              defaultValue="Brand"
              className='selectFiltBtn'
              onChange={handleBrandChange}
              options={optionsBrand}
            />
          </div>
          <div className="category"  >
          <TreeSelect
                showSearch
                className="selectFiltBtn"
                value={value}
                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                placeholder="Select Category"
                allowClear
                treeDefaultExpandAll
                onChange={handleCategoryChange}
                treeData={options}
              />
            {/* <Select
              id="brandSelect"
              defaultValue="Category"
              className='selectFiltBtn'
              onChange={handleCategoryChange}
              options={optionsCategory}
            /> */}
          </div>
          <DeleteItem
            toggle={toggleDelete}
            name={productName}
            itemsId={productId}
            deleteService={deleteProductService}
            closeModal={(e: any) => {
              setToggleDelete(e);
            }} />
        </div>
        <div className="content" style={{ display: "flex", flexWrap: "wrap", gap: "20px", marginBottom: "24px" }}>
          {productList && productList?.length > 0 &&
            productList?.map((data, index) => {
              const { productName, productId, brand, mrp, rlp, category, caseQty, isActive, image } = data;
              return (
                <div className='orderContainer' key={index}>
                  <div className='ordHeadline'>
                    <span className='ordertitle'>{productName}</span>
                    <div className={isActive ? "activetag" : "inActivetag"} style={{ margin: "0 0px 10px 0px" }}>
                      <span
                        className={isActive ? "blinker" : "blinker-inActive"}>
                      </span>
                      <span>{isActive ? "Active" : "Inactive"}</span>
                    </div>
                    {authState?.user?.role === UserRole.ADMIN && <span className='ordertitle'>
                      <Link to={`/admin/product/add-new-product?productId=${productId}`} className='linkDefault'>
                        <FormOutlined style={{ fontSize: "14px" }} />
                      </Link>
                      <DeleteOutlined onClick={() => toggleHandler(productId, productName)} className="deleteIcon" style={{ fontSize: "14px", paddingLeft: "10px" }} />
                    </span>}
                  </div>
                  <div className='orderList'>
                    <div className='title'>
                      <div className='mrpPrice'>
                        <div>
                          <div>
                            <span>Brand: <span className='fbold'>{brand?.name}</span></span>
                          </div>
                          <span>MRP: <span className='fbold'>₹ {mrp}</span></span>
                        </div>
                        <div>
                          <span>RLP: <span className='fbold'>₹ {rlp}</span></span>
                        </div>
                      </div>
                    </div>
                    <div className='title'>
                      <div className='mrpPrice'>
                        <div>
                          <div>
                            <span>Category: <span className='fbold'>{category?.name}</span></span>
                          </div>
                          <span>SKU ID: <span className='fbold'>{productId}</span></span>
                        </div>
                        <div>
                          <span>Case Qty: <span className='fbold'>{caseQty}</span></span>
                        </div>
                      </div>
                    </div>
                    <div className='title fxbtm'>
                      <img src={image} alt="productImg" width={60} height={60} 
                      onError={handleImageError}/>
                    </div>
                  </div>
                </div>
              )
            })}
        </div>
      </main>
    </div>
  )
}