import React, { useEffect, useState } from "react";
import "../../style/orderList.css";
import { ArrowLeftOutlined, EyeOutlined, FormOutlined, PlusOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "redux-store/reducer";
import { AppDispatch } from "redux-store/store";
import { getProductSchemeActions } from "redux-store/action/productAction";
// import { deleteProductSchemeService } from "services/productService";
import previousPage from "utils/previousPage";
import { useAuth } from "context/AuthContext";
import { UserRole } from "enum/common";
// import DeleteItem from "../common/deleteItem";

export default function Scheme() {

  const [toggleDelete, setToggleDelete] = useState(false);
  const [schemeName, setSchemeName] = useState('');
  const [schemeId, setSchemeID] = useState('');

  const productSchemeListData = useSelector(state => state?.product?.SchemeList);
  const [productSchemeList, setProductSchemeList] = useState<any[]>([]);
  const dispatch = useDispatch<AppDispatch>();

  const {authState} = useAuth()


  useEffect(() => {
    dispatch(getProductSchemeActions());      
  }, []);

  useEffect(() => {
    setProductSchemeList(productSchemeListData);
  }, [productSchemeListData])

  const searchStore = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { value } = e.target;
    const FS = productSchemeListData.filter((item: any) =>
      (item?.name?.toLowerCase())?.includes(value.toLowerCase())
    );
    setProductSchemeList(FS);
  };
  
  const toggleHandler = (schemeId: string, name: string) =>{
    setToggleDelete(true);
    setSchemeID(schemeId);
    setSchemeName(name)
  }

  const openPdfInNewTab = (file: any) => {
    window.open(file, '_blank');
  };

  return (
    <div>
      <header className="heading heading-container">
        <ArrowLeftOutlined onClick={previousPage} className="back-button"/>
          <h1 className="page-title pr-18">Scheme PDF</h1>
        </header>
     {authState?.user?.role === UserRole.ADMIN&& <Link to="/admin/add-new-scheme">
        <div className="addIcon">
          <PlusOutlined className="plusIcon"/>
        </div>
      </Link>}
      <main style={{marginTop: "10px"}}>
        <div className="searchproduct">
          <div>
          {/* <DeleteItem 
          toggle={toggleDelete} 
          name={schemeName} 
          itemsId={schemeId} 
          deleteService={deleteProductSchemeService}
          closeModal={(e: any) => {
            setToggleDelete(e);
          }}/> */}
            <table className="fs-11">
              <thead>
                <tr className="fs-11">
                  <th className="schemeTh txtC">Id</th>
                  <th className="schemeTh txtC">Name</th>
                  <th className="schemeTh txtC">Month &amp; Year</th>
                  <th className="schemeTh txtC">View</th>
                  <th className="schemeTh txtC">Status</th>
                  <th className="schemeTh txtC"></th>
 
                </tr>
              </thead>
              <tbody>
                {
                  productSchemeListData && productSchemeListData?.length > 0 &&
                  productSchemeListData?.map((data, index) => {
                    const { id, name, month, year, file, isEnable } = data;
                    return (
                      <tr key={index}>
                        <td className="txtC">{id}</td>
                        <td className="txtC">{name}</td>
                        <td className="txtC">{`${month} - ${year}`}</td>
                        <td className="txtC">
                          <EyeOutlined className="eyeIcon" onClick={(e: any) => openPdfInNewTab(file)} />
                        </td>
                        <td className={isEnable ? "schemeActive txtC" : "schemeInactive txtC"}>
                          {isEnable ? "Active" : "Inactive"}
                        </td>
                        <td>
                  <Link to={`/admin/add-new-scheme?schemeId=${id}`} className='linkDefault'>
                    <FormOutlined />
                  </Link>
                  </td>
                  {/* <td>
                    <DeleteOutlined onClick={() => toggleHandler(schemeId, name)} className="deleteIcon"/>
                  </td> */}
                      </tr>
                    )
                  })
                }
              </tbody>
            </table>
          </div>
        </div>
      </main>
    </div>
  );
}
