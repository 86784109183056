import { LoadingOutlined } from "@ant-design/icons";
import { Spin } from "antd";
import Pills from "component/Pills";
import RupeeSymbol from "component/RupeeSymbol";
import { PaymentStatus } from "enum/order";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getOrderListByStoreIdService } from "services/orderService";
import { IGetOrderHistoryData } from "types/Order";
import { IVisitParams } from "types/Visits";
import { dateFormatterNew } from "utils/common";

function PastOrdersDetails() {
  const [isLoading, setIsLoading] = useState(false);
  const params = useParams<IVisitParams>();
  const [orderList, setOrderList] = useState<IGetOrderHistoryData[]>([]);

  useEffect(() => {
    async function getOrderHistory() {
      try {
        if (params.storeId) {
          setIsLoading(true);
          const res = await getOrderListByStoreIdService(params.storeId)
          setIsLoading(false);
          if (res && res.data.status === 200) {
            setOrderList(res.data.data)
          }
        }
      } catch (error) {

      }
    }
    getOrderHistory();
  }, [params.storeId]);

  return (
    <table className="pastOrderTable">
      <thead>
        <tr>
          <th className="inveContainer">Date</th>
          <th className="inveContainer">Order ID</th>
          <th className="inveContainer">Order Amount</th>
          <th className="inveContainer">Collection Status</th>
        </tr>
      </thead>
      <tbody>
        {isLoading && <tr className="invenData">
          <td colSpan={4} className="text-align-center"><Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /></td>
        </tr>}
        {
          !isLoading && (
            orderList?.length === 0 ?
              <div>No order found</div>
              :
              orderList?.map(i => {
                return (
                  <tr className="invenData" key={i.orderId}>
                    <td className="invenData">{dateFormatterNew(i.orderDate)}</td>
                    <td className="invenDataId"><Link to={`/order/order-summary/${i.orderId}`}>{i.orderId}</Link></td>
                    <td className="invenData"><RupeeSymbol />{i.netAmount}</td>
                    <td className="invenData">
                      <Pills
                        type={i.paymentStatus === PaymentStatus.SUCCESS ? "success" : "error"}>
                        {i.paymentStatus === PaymentStatus.SUCCESS ? "paid" : "pending"}
                      </Pills>
                    </td>
                  </tr>
                )
              })

          )
        }
      </tbody>
    </table>
  );
}


export default PastOrdersDetails